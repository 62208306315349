import { useEffect, useState } from "react";


// TODO: Content manager (insert into database)

const mockup = [
    {
        title: 'Optimiza el monitoreo ambiental con la Plataforma Focus',
        image: 'https://media.licdn.com/dms/image/v2/D4E22AQHrc6nt7HRaog/feedshare-shrink_800/feedshare-shrink_800/0/1724951228918?e=1727913600&v=beta&t=kVhPVLoRxsETS-4wKj1vfk_m_w_KMzwyNSqtiEGprRQ',
        link: 'https://www.linkedin.com/posts/sanolivar_sanolivar-plataformafocus-medioambiente-activity-7234969842603233280-3mnN?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: ' Protege tus cultivos y maximiza la productividad con la Plataforma Focus! ',
        image: 'https://media.licdn.com/dms/image/v2/D5622AQHy_LiVkHcYMA/feedshare-shrink_800/feedshare-shrink_800/0/1724886762680?e=1727913600&v=beta&t=6iJvfQxu_jVPk_fYXGrFJTqQzYThrV_nybb-HS0SQEE',
        link: 'https://www.linkedin.com/posts/sanolivar_agriculturasostenible-tecnologaedaagraedcola-activity-7234699453989826560-SwUA?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: 'Transforma tu granja avícola con la Plataforma Focus',
        image: 'https://media.licdn.com/dms/image/v2/D4E22AQHlhGEFvp1X1A/feedshare-shrink_800/feedshare-shrink_800/0/1724434663239?e=1727913600&v=beta&t=e1xveggzjW5K8m7I2cUrEGJdWn0K4Uybmu88Vcx1H4M',
        link: 'https://www.linkedin.com/posts/sanolivar_agriculturainteligente-avicultura-tecnologaedaavaedcola-activity-7232803211936428032-JsVV?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: 'Transforma el Futuro con Focus Ambiental',
        image: 'https://media.licdn.com/dms/image/v2/D4D22AQGJ1HcRiHnWKw/feedshare-shrink_800/feedshare-shrink_800/0/1724342395833?e=1727913600&v=beta&t=pQLCYyTaMh2CI-m4cPcE2gTzbMhDGPPioLd9mgT5upU',
        link: 'https://www.linkedin.com/posts/sanolivar_huelladecarbono-sostenibilidad-monitoreoambiental-activity-7232416214398992386-RKZk?utm_source=share&utm_medium=member_desktop'
    },
    {
        title: '¡Descubre Focus! La Plataforma IoT que revoluciona sectores clave',
        image: 'https://media.licdn.com/dms/image/v2/D4E22AQG7cLato-srbg/feedshare-shrink_800/feedshare-shrink_800/0/1724248869166?e=1727913600&v=beta&t=ZzoKvuSuF1gUgwbv6tnS8k_7UHEGDe-OUsYeR9Hs37s',
        link: 'https://www.linkedin.com/posts/sanolivar_iot-innovaciaejntecnolaejgica-agriculturainteligente-activity-7232023931975585792-Z3Zp?utm_source=share&utm_medium=member_desktop'
    },

]

export default () => {
    useEffect(() => {
        // invert scroll on news pager
        const pager = document.querySelector('#news-pager');
        pager.addEventListener('wheel', e => {
            if (pager.scrollLeft == 0 && e.deltaY < 0) return;
            if (pager.scrollLeft + pager.clientWidth >= pager.scrollWidth && e.deltaY > 0) return;
            e.preventDefault();
            // termporally disable snap scrolling
            pager.style.scrollSnapType = 'none';
            pager.scrollLeft += e.deltaY > 0 ? 300 : -300;
            // Re-enable snap scrolling
            setTimeout(() => {
                pager.style.scrollSnapType = 'x mandatory';
            }, 50);
        })
    }, [])

    return (
        // background color matching banner background
        <section className="fw dark-blue">
            <strong>Nuestras últimas noticias en LinkedIn</strong>
            <div id="news-pager" className="pager" data-aos="fade-up">
                {
                    mockup.map((post, index) => (
                        <a role="navigation" aria-label={`Ampliar noticia ${post.title} en LinkedIn.`} target="_blank" href={post.link} key={`news-card-${index}`} className="newsCard" style={{ backgroundImage: `url(${post.image})` }}>
                            <span>{post.title}</span>
                        </a>
                    ))
                }
            </div>
        </section>
    );
}